import React from 'react';

import Layout from '../components/Layout';
import PageFooter from '../components/PageFooter';
import SideBar from '../components/Sidebar';
import Scroll from '../components/Scroll';

const pic = require('../assets/images/profile_pic.png');

const sections = [
	{ id: 'top', name: 'Intro', icon: 'fa-home' },
	{ id: 'about', name: 'About Me', icon: 'fa-user' },
	{ id: 'community-impact', name: 'Community Impact', icon: 'fa-bullhorn' },
	{ id: 'resume', name: 'CV / Resume', icon: 'fa-list-alt' },
	{ id: 'contact', name: 'Get in Touch', icon: 'fa-envelope' },
];

const IndexPage = () => (
	<Layout>
		<SideBar sections={sections} />

		<div id="main">
			<section id="top" className="one dark cover">
				<div className="container">
					<header>
						<h2 className="alt">
							Hi! I'm <strong>Naman</strong>, a Front-End Engineer from Jaipur, India.
						</h2>
						<p>♥ building accessible, performant and responsive applications - using the Web stack.</p>
					</header>

					<footer>
						<Scroll type="id" element={'about'}>
							<a href="#about" className="button">
								Tell me more ⬇
							</a>
						</Scroll>
					</footer>
				</div>
			</section>

			<section id="about" className="two">
				<div className="container">
					<header>
						<h2>About Me</h2>
					</header>

					<a href="/#" className="image featured" aria-label="Featured image">
						<img src={pic} alt="" />
					</a>

					<p>Working as <strong>Sr. Front-End Engineer</strong> at <a target="_blank"
						rel="noopener noreferrer" href="https://aws.amazon.com">AWS</a> in Palo Alto, California.
					</p>

					<p>Worked at <a target="_blank" rel="noopener noreferrer" href="https://www.morganstanley.com">Morgan Stanley</a> in the past.</p>

					<p>Avid contributor to the developer community - talks | blogs | workshops / codelabs ...
					</p>

					<p>Short-distance <a target="_blank"
						rel="noopener noreferrer" href="https://www.strava.com/athletes/72659537">runner</a> | Football (<a target="_blank"
						rel="noopener noreferrer" href="https://www.chelseafc.com/">Chelsea</a>) fan + player | <a target="_blank"
						rel="noopener noreferrer" href="https://www.goodreads.com/user/show/48843112-naman-sancheti">Book lover</a>
					</p>

					<footer>
						<Scroll type="id" element={'community-impact'}>
							<a href="#community-impact" className="button">
								How I contribute ⬇
							</a>
						</Scroll>
					</footer>
				</div>
			</section>

			<section id="community-impact" className="three dark cover community-impact">
				<div className="container">
					<header>
						<h2 className="community-impact__title">Community Impact</h2>

						<h3>Talks</h3>
						<ul className="community-impact__list">
							<li>
								<div className="community-impact__item-title">WebAssembly: Re-defining capabilities of the Web</div>
								<div>@ ReactDay Bangalore</div>
								<a
									href="https://youtu.be/HWx6nkLE5Po?t=8348"
									target="_blank"
									rel="noopener noreferrer">Talk</a>
								<br />
								<a
									href="https://bit.ly/reactday-wa-naman"
									target="_blank"
									rel="noopener noreferrer">Slides</a>
							</li>
							<li>
								<div className="community-impact__item-title">Developing Accessible Applications</div>
								<div>@ Recro</div>
								<a
									href="https://youtu.be/SwhqebijUlk"
									target="_blank"
									rel="noopener noreferrer">Talk</a>
								<br />
								<a
									href="https://bit.ly/recro-a11y-naman"
									target="_blank"
									rel="noopener noreferrer">Slides</a>
							</li>
							<li>
								<div className="community-impact__item-title">Building VS Code Extensions</div>
								<div>@ ReactJS Bangalore Meetup</div>
								<a
									href="https://docs.google.com/presentation/d/1zdEa31QBqlU3XoTvnjk5eJPURwqRBez_WrwrxWzRtwg/view#slide=id.p"
									target="_blank"
									rel="noopener noreferrer">Slides</a>
								<br />
								<a
									href="https://www.meetup.com/ReactJS-Bangalore/events/260222287/"
									target="_blank"
									rel="noopener noreferrer">Meetup</a>
							</li>
							<li>
								<div className="community-impact__item-title">Building Web Components using Angular Elements</div>
								<div>@ Angular Bangalore Meetup</div>
								<a
									href="https://www.youtube.com/watch?v=sHH3fxyU5mU"
									target="_blank"
									rel="noopener noreferrer">Talk</a>
								<br />
								<a
									href="https://www.meetup.com/angular-bangalore-group/events/263773017/"
									target="_blank"
									rel="noopener noreferrer">Meetup</a>
							</li>
							<li>
								<div className="community-impact__item-title">Modern JavaScript (ES6)</div>
								<div>@ TechClub - Morgan Stanley</div>
								<div>Audience - 350+ developers</div>
								<a
									href="https://docs.google.com/presentation/d/1i-BPKzBHLPTkY0DfkTZPSqIpoHGyGozCfomKk8wWiUY/view#slide=id.p"
									target="_blank"
									rel="noopener noreferrer">Slides</a>
							</li>
							<li>
								<div className="community-impact__item-title">LintStudio: GUI for creating custom ESLint plugins</div>
								<div>@ Amazon - WebDevCon 2020</div>
							</li>
						</ul>

						<h3>Workshops / Codelabs</h3>
						<ul className="community-impact__list">
							<li>
								<div className="community-impact__item-title">Building VS Code Extensions</div>
								<div>@ JSLovers Bangalore Meetup</div>
								<a
									href="https://docs.google.com/presentation/d/1pKdhg7l8LG8VBCkhfGZEKgkmXJdt8eafwLyXNpnhaLc/view#slide=id.g5ce3151557_0_125"
									target="_blank"
									rel="noopener noreferrer">Slides</a>
								<br />
								<a
									href="https://github.com/namansancheti/rxjs-buddy"
									target="_blank"
									rel="noopener noreferrer">Codelab</a>
								<br />
								<a
									href="https://www.meetup.com/jslovers-bengaluru/events/262275286/"
									target="_blank"
									rel="noopener noreferrer">Meetup</a>
							</li>
							<li>
								<div className="community-impact__item-title">Trainer - JS</div>
								<div>@ Spring Intern training - Morgan Stanley</div>
								<div>Audience - 20+ interns</div>
							</li>
							<li>
								<div className="community-impact__item-title">Trainer - Angular</div>
								<div>@ Spring intern training - Morgan Stanley</div>
								<div>Audience - 20+ interns</div>
							</li>
						</ul>

						<h3>Leadership</h3>
						<ul className="community-impact__list">
							<li>
								<div className="community-impact__item-title">Coordinator</div>
								<div>@ Knuth Programming Hub - JIIT Noida</div>
								<a
									href="http://www.jiit.ac.in/knuth-programming-hub"
									target="_blank"
									rel="noopener noreferrer">Official page</a>
							</li>
							<li>
								<div className="community-impact__item-title">Coordinator - Technical Department</div>
								<div>@ Google Developers' Group - JIIT Noida </div>
								<a
									href="http://www.jiit.ac.in/gdg"
									target="_blank"
									rel="noopener noreferrer">Official page</a>
							</li>
							<li>
								<div className="community-impact__item-title">Lead organizer</div>
								<div>@ Giving back event - Samarthanam Trust, Bangalore</div>
								<div>Led team to conduct mock job interviews for 20+ disabled students.</div>
								<a
									href="https://www.samarthanam.org/"
									target="_blank"
									rel="noopener noreferrer">Website</a>
							</li>
						</ul>

						<h3 className="community-impact__section-title">Blogger</h3>
						<a
							href="https://medium.com/@naman.sancheti"
							target="_blank"
							rel="noopener noreferrer">View posts</a>

						<h3 className="community-impact__section-title">Stack Overflow</h3>
						<a
							href="https://stackoverflow.com/users/3709792/naman-sancheti"
							target="_blank"
							rel="noopener noreferrer">View profile</a>
					</header>

					<footer>
						<Scroll type="id" element={'resume'}>
							<a href="#resume" className="button">
								CV / Resume ⬇
							</a>
						</Scroll>
					</footer>
				</div>
			</section>

			<section id="resume" className="four">
				<div className="container">
					<header>
						<h2>CV / Resume</h2>
					</header>

					<a
						href="https://drive.google.com/file/d/1AmcKzE8zuqAW6fwlsIZ4fK_65g2DY3ku/view"
						target="_blank"
						rel="noopener noreferrer"
						className="button scrolly">View CV / Resume
					</a>
				</div>
			</section>

			<section id="contact" className="five dark cover contact">
				<div className="container">
					<header>
						<h2>Get in Touch</h2>
						<p>Want to collaborate ? Got a job opportunity ? Have queries ?</p>
						<p>Please reach out at [naman dot sancheti at gmail dot com].</p>
						<p>I promise to get back to you very soon... <span role="img" aria-label="smiling face">😃</span></p>
					</header>
				</div>
			</section>
		</div>

		<PageFooter />
	</Layout>
);

export default IndexPage;
